import React from 'react'
import Container from 'react-bootstrap/esm/Container'
import Nav from 'react-bootstrap/esm/Nav'
import Navbar from "react-bootstrap/Navbar"
import { Link } from 'react-router-dom';



//bg primary or dark 
const NavbarComp = ({ bg, type }) => {
    if (type === 'header') {
        return (
            <div>
                <Navbar bg={bg} variant="dark">
                    <Container>
                        <Navbar.Brand as={Link} to="/">Start</Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/cv">Lebenslauf</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </div>

        )
    } else if (type === 'footer') {
        return (
            <footer>
                <Navbar bg={bg} variant="dark">
                    <Container className='footer'>
                        <Nav className="footer" >
                            <a href='https://www.linkedin.com/in/daniel-korzhov-8a00301b7/' target='_blank' rel='noopener noreferrer' >LinkedIn</a>
                            <a href='https://www.xing.com/profile/Daniel_Korzhov/cv' target='_blank' rel='noopener noreferrer' >Xing</a>
                        </Nav>
                    </Container>
                </Navbar>
            </footer>
        )
    }

}

export default NavbarComp

