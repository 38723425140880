import React from 'react'
import ProjectComp from './ProjectComp'
import './ProjectGridComp.css'
import Stack from 'react-bootstrap/Stack'

const ProjectGridComp = ({ projects, showProject, openDetails }) => {

    return (

        <Stack gap={2} className="grids">
            {projects.map((project,index) => (

                <div key={index}>
                    <ProjectComp  showProject={showProject} project={project} openDetails={openDetails} />
                </div>

            ))}

        </Stack>

    )
}

export default ProjectGridComp
