import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { SizeMe } from 'react-sizeme';

function Lebenslauf() {
    const [numPages, setNumPages] = useState(null);

    const pdfUrl = "https://cv-app-backend.s3.amazonaws.com/CV/CV.pdf";

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <SizeMe
            monitorHeight
            refreshRate={128}
            refreshMode={"debounce"}>
            {({ size }) => (
                <Container>
                    <h1 className='text caption'>Lebenslauf</h1>
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {numPages ? (
                            Array.from({ length: numPages }, (_, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width || 1} />
                            ))
                        ) : (
                            <p>Lade Seiten...</p>
                        )}
                    </Document>
                </Container>
            )}
        </SizeMe>
    );
}

export default Lebenslauf;
