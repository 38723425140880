import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import './CarouselComp.css'

function CarouselComp({ media }) {
  const [index, setIndex] = useState(0);

  const mediaType = (medium) => {
    if (medium.type === 'image') {
      return (<img
        className="d-block w-100"
        src={medium.src}
        alt="Second slide"
      />)
    } else if (medium.type === 'video') {
      return (<video style={{ height: '50%', width: '100%' }} controls>
        <source src={medium.src} type="video/mp4">
        </source>
      </video>)
    }
    else { return (<p>error</p>) }
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>

      {media.map((medium, index) => (
        <Carousel.Item key={index} interval={medium.length * 1000}>
          <Carousel.Caption>
            <p className={'text caption'.concat(' '+ medium.textColor)}>{medium.label}</p>
            <p className={'text'.concat(' '+ medium.textColor)}>{medium.description}</p>
          </Carousel.Caption>
          {mediaType(medium)}
        </Carousel.Item>
      )
      )
      }


    </Carousel>
  )
}

export default CarouselComp
