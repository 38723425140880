import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css'
import { useNavigate } from 'react-router-dom';
import ProjectGridComp from 'components/ProjectGridComp';
import ContactComp from 'components/ContactComp';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CopyTextComp from 'components/CopyTextComp';
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";


function App() {

  
  let navigate = useNavigate()
  const [projects, setProjects] = useState([])
  //AWS Client for S3 with authentication
  // const region = "eu-west-1"
  // const s3Client = new S3Client({
  //   region,
  //   credentials: fromCognitoIdentityPool({
  //     client: new CognitoIdentityClient({ region }),
  //     identityPoolId: "eu-west-1:cd1d8b28-511e-4149-98de-6061e28b770c",
  //   }),
  // });
  // const s3Client = new S3Client({region: "eu-west-1"});
  

  const fetchProjectsJson = async () => {
    const res = await fetch('https://cv-app-backend.s3.eu-west-1.amazonaws.com/db.json')
    const data = await res.json()
    return data
    // const command = new GetObjectCommand({
    //   Bucket: "cv-app-backend",
    //   Key: "db.json"
    // });
    // const data = await s3Client.send(command);

    // return JSON.parse(data.Body.toString())
  }

  useEffect(() => {
    const getProjects = async () => {
      const projectsFromServer = await fetchProjectsJson()
      setProjects(projectsFromServer.projects)
    }
    getProjects()
  }, [])




  const showProject = (id) => {
    setProjects(
      projects.map((project) =>
        project.id === id ? { ...project, isShown: true } : project
      )
    )
  }

  const openDetails = (id) => {
    navigate('/project/' + id)
  }


  return (

    <Container className='App'>
      <div className='welcome'>
        <h1 className='text caption'>Daniel Korzhov</h1>
        <video autoPlay={true} className='welcome video' playsInline muted  >
          <source src='/assets/videos/welcomeAnimation.mp4' type='video/mp4' />
        </video>
      </div>
      <div className='projects'>
        <h1 className='text caption'>Projekte</h1>
        <ProjectGridComp projects={projects} showProject={showProject} openDetails={openDetails} />
      </div>
      <Container className='cv'>
        <h1 className='text caption'>Lebenslauf</h1>
        <p className='text caption'>Hier kann man meinen aktuellen&nbsp;
          <Link className='text' to='/cv'>Lebenslauf</Link>
          <span>&nbsp;ansehen.</span>
        </p>
      </Container>
      <div className='contact'>
        <h1 className='text caption'>Kontakt</h1>
        <div className='text caption'>
          <p>Schreiben Sie mir eine E-mail an:</p>
          <CopyTextComp text='daniel.korzhov@gmail.com' isMail/>
        </div>
      </div>

    </Container>


  );
}

export default App;
