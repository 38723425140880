import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import 'aos/dist/aos.css'
import './ProjectComp.css'
import { useVisible } from 'react-visible-image'


const ProjectComp = ({ project, showProject, openDetails }) => {

    const ref = useRef()
    const visible = useVisible(ref);
    useEffect(() => {
        const show = async () => {
            if (visible) {
                showProject(project.id)
            }
        }
        show()
    }, [visible])

    return (

        <Container className='boxes' onClick={() => openDetails(project.id)}>
            
            <div data-aos='fade-left'>
                <div className='emptyImageProj'>
                    <Image src='/assets/images/EmptyProj.png' fluid />
                </div>

                <div className={`imageProj ${project.isShown ? 'normalSize' : ''}`}>
                    <Image src={project.projImg} fluid />
                </div>
                <h3 className="textBox projName">{project.projName}</h3>
                <h3 className="textBox shortProjDescription">{project.shortProjDescription}</h3>
            </div>
            {/* Anchor for visible elements. To start visible animation after AOS animation. */}
            <div ref={ref} />
        </Container>

    )
}

export default ProjectComp
