import React from 'react'
import { Button, Col, Form, Row, Container } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup'
import './ContactComp.css'

const schema = yup.object().shape({
    firstName: yup.string().required('Vorname erforderlich'),
    lastName: yup.string().required('Nachname erforderlich'),
    email: yup.string().email('Email ungültig').required('Email erforderlich'),
    message: yup.string().required('Nachricht erforderlich')
});

const handleSubmit = (e) => {
    for (let element of e.target) {
        console.log(element.value)
    }

}



function ContactComp() {
    return (
        <Container>

            <Formik
                validationSchema={schema}
                onSubmit={console.log}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    message: 'Hallo Daniel,'
                }}
            >
                {({
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                }) => (
                    <Form noValidate onSubmit={handleSubmit} className='form'>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md
                                controlId="validationFormik101"
                                className="position-relative"
                            >
                                <Form.Label>Vorname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.firstName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md
                                controlId="validationFormik102"
                                className="position-relative"
                            >
                                <Form.Label>Nachname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.lastName}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md
                                controlId="validationFormik103"
                                className="position-relative"
                            >
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md
                                controlId="validationFormik104"
                                className="position-relative"
                            >
                                <Form.Label>Nachricht</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Ihre Nachricht"
                                    style={{ height: '400px' }}
                                    size="lg"
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                    isInvalid={!!errors.message}
                                />
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <div className="submitButton">
                            <Button type="submit">Senden</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>

    );
}

export default ContactComp
