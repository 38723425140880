import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import './CopyTextComp.css'
import '../index.css'

const CopyTextComp = ({ text }, { isMail }) => {

    const [isClicked, setClicked] = useState(false);
    const [buttonSuccess, setButtonSuccess] = useState(false)


    function copyText() {
        navigator.clipboard.writeText({ text }.text)
        setClicked(true)
        setButtonSuccess(true)
        setTimeout(() => { setClicked(false); setButtonSuccess(false) }, 1000)
    }


    if ({ isMail }) {
        return (

            <div className='copy-comp'>
                <a className='text black copy-text' href={`mailto:` + { text }.text}>{text}</a>
                <Button variant={buttonSuccess ? "success" : "outline-secondary"} size="sm" className="copy-button" onClick={copyText}>{isClicked ? '✓' : 'kopieren'}</Button>
            </div>
        )
    }

}

export default CopyTextComp
