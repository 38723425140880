import React, { createElement } from 'react'
import CarouselComp from './CarouselComp'


const keysToComponentMap = {
    div: 'div',
    p: 'p',
    li: 'li',
    null: React.Fragment,
    a: 'a',
    span: 'span',
    carousel: CarouselComp,
}

export const RenderComp = (config) => {
    if (typeof keysToComponentMap[config.component] !== 'undefined') {
        return createElement(
            keysToComponentMap[config.component],
            {
                id: config.id ? config.id : null,
                key: config.key ? config.key : null,
                className: config.className ? config.className : null,
                media: config.media ? config.media : null,
                href: config.href ? config.href : null,
                rel: config.rel ? config.rel : null,
                target: config.target ? config.target : null
            },
            config.children &&
            (typeof config.children === 'string'
                ? config.children
                : config.children.map((c) => RenderComp(c)))
        );
    }
}
