import { RenderComp } from 'components/RenderComp';
import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router'


function Projekt() {
  const params = useParams()
  const projID = params.id
  const [project, setProject] = useState(Object);
  const [projectConfig, setProjectConfig] = useState(Object)


  const fetchProjectJson = async () => {
    const res = await fetch('https://cv-app-backend.s3.eu-west-1.amazonaws.com/db.json')
    const data = await res.json()
    return data.projects.filter(item => item.id == projID)[0]
    
  }


  useEffect(() => {
    const getProject = async () => {
      const projectFromServer = await fetchProjectJson()
      setProject(projectFromServer)
      setProjectConfig(projectFromServer.config)
    }
    getProject()
  }, [])


  return (
    <Container className='Project'>
      <Card>
        <Card.Header>
          <h1 className='text caption'>{project.projName}</h1>
        </Card.Header>
        <Card.Body className='projectBody'>
        {RenderComp(projectConfig)}
        </Card.Body>
        <Card.Footer>
          <p className='text caption'>Genutzte Technologien</p>
          <p className='text'>{project.technologies}</p>
        </Card.Footer>
      </Card>



    </Container>
  )
}

export default Projekt
