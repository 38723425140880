import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavbarComp from 'components/NavbarComp';
import Projekt from 'Projekt';
import Lebenslauf from 'Lebenslauf';


ReactDOM.render(
  <React.StrictMode>

    <BrowserRouter>
      <NavbarComp bg="dark" type='header' />
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/project/:id' element={<Projekt />} />
        <Route path='/cv'element={<Lebenslauf/>}/>
      </Routes>
      <NavbarComp bg="dark" type='footer'/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
